import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import "./css/detail.css";

export default function Detail() {
  const { isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { shopName } = location.state || {}; // Accessing the state

  const [allBills, setAllBills] = useState([]);
  const [bills, setBills] = useState([]);
  const [error, setError] = useState(null); // State to store error messages
  const [startMonth, setStartMonth] = useState(""); // State to manage start month
  const [endMonth, setEndMonth] = useState(""); // State to manage end month
  const [warning, setWarning] = useState(""); // State to manage warning messages

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate("/login");
    } else {
      const fetchBillDetails = async () => {
        try {
          const response = await fetch(
            "http://nansijewellers.com/php/get_bill_details.php",
            {
              method: "POST", // Change to POST
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ shopName }), // Include the shopName in the body
              credentials: "include",
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          if (data.success) {
            setBills(data.bills);
            setAllBills(data.bills);
          } else {
            setError(data.message);
            console.error("Failed to fetch shops:", data.message);
          }
          console.log("data:", data);
        } catch (error) {
          console.error("Error:", error);
          setError(error.message);
        }
      };

      fetchBillDetails();
    }
  }, [isAuthenticated, loading, navigate, shopName]);

  const handleStartMonthChange = (e) => {
    setStartMonth(e.target.value);
    setWarning("");
  };

  const handleEndMonthChange = (e) => {
    setEndMonth(e.target.value);
    setWarning("");
  };

  const handleSort = () => {
    if (!startMonth || !endMonth) {
      setWarning("Please select both start and end months.");
      return;
    }

    const startDate = new Date(startMonth);
    const endDate = new Date(endMonth);

    // Adjust endDate to the last day of the month
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);

    if (startDate > endDate) {
      setWarning("End month should be after the start month.");
      return;
    }

    const filtered = allBills.filter((bill) => {
      const billDate = new Date(bill.date);
      return billDate >= startDate && billDate <= endDate;
    });

    if (filtered.length === 0) {
      setWarning("No bills available for the selected date range.");
    } else {
      setWarning("");
    }

    setBills(filtered);
  };

  useEffect(() => {
    if (warning) {
      const timer = setTimeout(() => {
        setWarning("");
      }, 5000);
      return () => clearTimeout(timer); // Clear the timer if warning changes or component unmounts
    }
  }, [warning]);

  const calculateTotals = () => {
    let totalPositiveFineGold = 0;
    let totalNegativeFineGold = 0;
    let totalPositiveAmount = 0;
    let totalNegativeAmount = 0;

    bills.forEach((bill) => {
      let fineGold;
      let amount;

      if (bill.fine_gold !== "") {
        fineGold = parseFloat(bill.fine_gold);
      } else {
        fineGold = 0;
      }
      if (bill.amount !== "") {
        amount = parseFloat(bill.amount);
      } else {
        amount = 0;
      }

      if (fineGold > 0) {
        totalPositiveFineGold += fineGold;
      } else {
        totalNegativeFineGold += fineGold;
      }
      if (amount > 0) {
        totalPositiveAmount += amount;
      } else {
        totalNegativeAmount += amount;
      }
    });

    return {
      totalPositiveFineGold,
      totalNegativeFineGold,
      finalFineGold: totalPositiveFineGold + totalNegativeFineGold,
      totalPositiveAmount,
      totalNegativeAmount,
      finalAmount: totalPositiveAmount + totalNegativeAmount,
    };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}-${month}-${year}`;
  };

  const {
    totalPositiveFineGold,
    totalNegativeFineGold,
    finalFineGold,
    totalPositiveAmount,
    totalNegativeAmount,
    finalAmount,
  } = calculateTotals();

  if (loading) {
    return <div>Loading...</div>; // Show loading state while checking authentication
  }

  if (error) {
    return <div>Error: {error}</div>; // Show error message if any
  }

  const handlePrint = () => {
    window.location.href = `http://nansijewellers.com/php/sales_print.php?shopName=${shopName}&sm=${startMonth}&em=${endMonth}`; // Redirect to the PHP page with the necessary query parameter
  };

  return (
    <div>
      <div className="container contantt">
        <header className="row align-items-center">
          <div className="col-md-auto">
            <div className="header-title-wrapper">
              <div className="header-title">
                <h5 className="shop-name">{shopName}</h5>{" "}
                {/* Display the shop name with new style */}
              </div>
            </div>
          </div>
        </header>

        <div className="row mb-3 justify-content-center">
          <div className="col-md-8">
            <div className="input-group">
              <button onClick={handlePrint} id="Start_BTN" className="btn btn-primary">
                Print
              </button>
              <div className="form-floating">
                <input
                  type="month"
                  id="startMonth"
                  className="form-control"
                  value={startMonth}
                  onChange={handleStartMonthChange}
                  placeholder="Start Date"
                />
                <label htmlFor="startMonth">Select Start Month:</label>
              </div>
              <div className="form-floating">
                <input
                  type="month"
                  id="endMonth"
                  className="form-control"
                  value={endMonth}
                  onChange={handleEndMonthChange}
                  placeholder="End Date"
                />
                <label htmlFor="endMonth">Select End Month:</label>
              </div>

              <button
                id="End_BTN"
                className="btn btn-primary"
                onClick={handleSort}
              >
                Sort
              </button>
            </div>
          </div>
        </div>

        {warning && (
          <div className="alert alert-warning" role="alert">
            {warning}
          </div>
        )}

        <div className="table-responsive table-box">
          <table className="table mb-3 table-bordered table-hover">
            <thead id="detail_table">
              <tr>
                <th scope="col">S no.</th>
                <th scope="col">Date</th>
                <th scope="col">Deal Type</th>
                <th scope="col">Item</th>
                <th scope="col">Net wt.</th>
                <th scope="col">T+lbr</th>
                <th scope="col">Fine Gold</th>
                <th scope="col">Gold Rate</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody id="detail_table">
              {bills.map((bill, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{formatDate(bill.date)}</td>
                  <td className={bill.deal_type}>{bill.deal_type}</td>
                  <td>{bill.item_name}</td>
                  <td>{bill.net_wt}</td>
                  <td>{bill.touch_lbr}</td>
                  <td className={bill.deal_type}>{bill.fine_gold}</td>
                  <td>{bill.gold_rate}</td>
                  <td className={bill.deal_type}>{bill.amount}</td>
                </tr>
              ))}
              <tr>
                <th scope="col" colSpan="6">
                  Total Sale
                </th>
                <th>{totalPositiveFineGold} g</th>
                <th></th>
                <th>{totalPositiveAmount} /-</th>
              </tr>
              <tr>
                <th scope="col" colSpan="6">
                  Total Deposit
                </th>
                <th>{totalNegativeFineGold} g</th>
                <th></th>
                <th>{totalNegativeAmount} /-</th>
              </tr>
              <tr>
                <th scope="col" colSpan="6">
                  Final Result
                </th>
                <th>{finalFineGold} g</th>
                <th></th>
                <th>{finalAmount} /-</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

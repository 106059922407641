import React from "react";

import "./css/home.css";

export default function Home() {
  return (
    <div>
      {/* <h1 className="homeHeading">Home Page</h1> */}

      <div className="container-fluid p-0">
        <img src="images/home.png" alt="" width="100%" />
      </div>


      {/* first block */}

      <div className="container-fluid" id="heading">
        <h3 className="homeHeading">Gold Collection</h3>
        <p>
          Prepare to elevate your sense of style with our latest collection!
        </p>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row col-md-12 align-items-center">
            <div className="col-md-4" id="content_1">
              <img src="images/c1.jpeg" alt="" width="100%" />
            </div>
            <div className="col-md-8">
              <div className="row col-md-12">
                <div className="row my-2">
                  <div className="col" id="content_1">
                    <img
                      src="images/Gold_Chain.png"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>

                  <div className="col" id="content_1">
                    <img
                      src="images/Gold_Nacklesh.png"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col" id="content_1">
                    <img
                      src="images/Gold_Ring.png"
                      alt="product"
                      className="p-0 mx-auto"
                      width="100%"
                    />
                  </div>

                  <div className="col" id="content_1">
                    <img
                      src="images/Gold_Bracelet.png"
                      alt="product"
                      className="p-0 mx-auto"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* secont block  */}

      <div className="container-fluid" id="heading">
        <h3 className="homeHeading">Gem Stone Jewellery</h3>
      </div>

      <div className="container-fluid">
        {/* <div className="container"> */}
        <div className="row col-md-12">
          <div className="col-md-6">
            <div className="row">
              <div className="col" id="block">
                <img src="images/earring.jpeg" alt="product" width="100%" />
              </div>

              <div className="col" id="block">
                <img src="images/ring.jpg" alt="product" width="100%" />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col" id="block">
                <img src="images/locket.jpeg" alt="product" width="100%" />
              </div>

              <div className="col" id="block">
                <img src="images/locket1.jpeg" alt="product" width="100%" />
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      {/* third block */}

      <div className="container-fluid" id="heading">
        <h3 className="homeHeading">Bridal Collection</h3>
        <p>Start the journey of your new life with us</p>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row col-md-12 align-items-center">
            <div className="col-md-4">
              <div className="row col-md-12">
                <div className="row">
                  <div className="col" id="content_3">
                    <img
                      src="images/z.jpeg"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col" id="content_3">
                    <img
                      src="images/z2.jpeg"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <img src="images/bride.jpeg" alt="" width="100%" />
              <h6>All kinds of Gold, Silver and Diamond Jewellery sets</h6>
            </div>
            <div className="col-md-4">
              <div className="row col-md-12">
                <div className="row">
                  <div className="col">
                     <img
                      src="images/z3.jpg"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <img
                      src="images/z1.jpeg"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* fourth block */}

      <div className="container-fluid" id="heading">
        <h3 className="homeHeading">Silver Collection</h3>
        <p>
          Prepare to elevate your sense of style with our latest collection!
        </p>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row col-md-12">
            <div className="col-md-6" id="content_1">
              <div className="row col-md-12">
                <div className="row">
                  <div className="col" id="content_1">
                    <img
                      src="images/1.png"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                    {/* <h6>Block1</h6> */}
                  </div>

                  <div className="col" id="content_1">
                    <img
                      src="images/2.png"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col" id="content_1">
                    <img
                      src="images/3.png"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>

                  <div className="col" id="content_1">
                    <img
                      src="images/4.png"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row col-md-12">
                <div className="row">
                  <div className="col" id="content_1">
                    <img
                      src="images/5.png"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                    {/* <h6>Block1</h6> */}
                  </div>

                  <div className="col" id="content_1">
                    <img
                      src="images/6.png"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col" id="content_1">
                    <img
                      src="images/7.png"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>

                  <div className="col" id="content_1">
                    <img
                      src="images/8.png"
                      alt="product"
                      className="p-1 mx-auto"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="container-fluid" id="heading">
        <hr />
        <h5>
          Developed By: Integration IT Services Pvt. Ltd.
          
        </h5>
        <hr />
      </div>

    </div>
  );
}

import React from "react";

import "./css/about.css";

export default function About() {
  return (
    <div>
      <div className="container-fluid">
        <img src="images/aboutus.png" alt="" width="100%" />

        <div className="container">
          <div className="row align-items-center justify-content-center
           py-4">
            <div className="col-md-4" id="about_content_1">
              <img src="images/about.jpg" alt="" width="100%" />
            </div>
            <div className="col-md-4" id="about_content_2">
              <p>
                Jewellery is not a product but a manifestation of artistry and
                our exquisite range of jewel pieces strike the perfect balance
                between traditional charm and contemporary appeal. With designs
                that capture the beauty and celebration of special occasions in
                the life of the Indian woman.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" id="heading">
        <hr />
        <h5>
          Developed By: Integration IT Services Pvt. Ltd.
        </h5>
        <hr />
      </div>

    </div>
  );
}

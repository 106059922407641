import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/bill.css"; // Assuming you have a CSS file for styling

export default function Registration() {
  const [formData, setFormData] = useState({
    shop_name: "",
    shop_owner_name: "",
    phone_no: "",
    email: "",
    address: "",
    city: "",
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "http://nansijewellers.com/php/register_shop.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      if (data.success) {
        setMessage("Shop registered successfully!");
        setTimeout(() => {
          navigate("/dashboard"); // Redirect to the dashboard page
        }, 2000); // 2 seconds delay before redirecting
      } else {
        setMessage("Failed to register shop.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div>
      <div className="container-fluid" id="content1">
        <div className="row justify-content-center py-4">
          <div className="col-md-6">
            <section className="container bill-section">
              <header>New Shop Registration</header>

              <form method="post" className="form" onSubmit={handleSubmit}>
                <div className="column">
                  <div className="input-box">
                    <label>Shop Name :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="shop_name"
                      placeholder="Enter Shop Name"
                      value={formData.shop_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input-box">
                    <label>Shop Owner Name :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="shop_owner_name"
                      placeholder="Enter the name of shop owner"
                      value={formData.shop_owner_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="input-box">
                    <label>Phone number :</label>
                    <input
                      className="mt-0"
                      type="number"
                      name="phone_no"
                      placeholder="Enter Phone Number"
                      value={formData.phone_no}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="input-box">
                    <label>Email :</label>
                    <input
                      className="mt-0"
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="input-box">
                    <label>Address :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="address"
                      placeholder="Enter Address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input-box">
                    <label>City :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="city"
                      placeholder="Enter city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <input
                  type="submit"
                  value="Submit"
                  id="submit_btn"
                  name="Submit"
                />
              </form>
              {message && <p>{message}</p>}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

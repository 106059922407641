import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/login.css";
import { useAuth } from '../AuthContext'; // Correct import path if necessary

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await login(email, password); // Call the login function from useAuth

    setMessage(result.message);

    if (result.success) {
      navigate("/dashboard");
    } else {
      console.error(result.message); // Handle error appropriately
    }
  };

  return (
    <div className="login-form">
      <div className="container-fluid">
        <div className="container">
          <div className="row justify-content-center py-4">
            <div className="col-md-8">
              <div className="col-md-10 formSection mx-auto">
                <div className="login_block">
                  <div className="login_innerBlock">
                    <h3>Login</h3>
                    <hr />
                    <form onSubmit={handleSubmit}>
                      <div className="login_formBlock">
                        <div className="mb-3">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <label>Password</label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter your Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <input
                            type="submit"
                            className="btn btn-primary"
                            id="submit"
                            value="Login"
                          />
                        </div>
                      </div>
                    </form>
                    {/* Display message if needed */}
                    {message && (
                      <p className={message.success ? "success" : "error"}>
                        {message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;

import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthToken = async () => {
      try {
        const response = await fetch(
          "http://nansijewellers.com/php/get_user.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.user) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Error checking auth token:", error);
      } finally {
        setLoading(false); // Ensure loading state is updated
      }
    };

    checkAuthToken();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await fetch(
        "http://nansijewellers.com/php/login.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ email, password }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.success) {
        setIsAuthenticated(true);
        return { success: true, message: "Login successful" };
      } else {
        return { success: false, message: "Incorrect ID or Password" };
      }
    } catch (error) {
      console.error("Error:", error);
      return { success: false, message: error.message };
    }
  };

  const logout = async () => {
    try {
      const response = await fetch(
        "http://nansijewellers.com/php/logout.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.success) {
        setIsAuthenticated(false);
        return { success: true, message: "Logged Out" };
      }
    } catch (error) {
      console.error("Error:", error);
      return { success: false, message: "Something went wrong" };
    }
  };

  const authContextValue = {
    isAuthenticated,
    loading,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import React from "react";

import "./css/contact.css";

export default function Contact() {
  return (
    <div>
      <div className="container-fluid">
        <div className="container">
          {/* <div class="row"> */}
          <div className="row justify-content-center py-4">
            <div className="col-md-6" id="contact_left">
              <img src="images/contactus.png" alt="" width="100%" />
              <section className="container-contact-details">
                <h4>Get in Touch</h4>
                <hr />
                <p>
                  <i className="fa-solid fa-envelope"></i> rajat01soni@gmail.com
                </p>
                <p>
                  <i className="fa-solid fa-phone"></i> 9406522729, 9685122729
                </p>
                <p>
                  <i className="fa-solid fa-location-dot"></i> TilakChok, Nandwana
                  Road, Vidisha(M.P)
                </p>
              </section>
            </div>

            {/* <div className="col-md-4" id="contact_right_img">
              <img src="images/contact4.png" alt="" width="100%" />
            </div> */}
          </div>
        </div>
      </div>

      <div className="container-fluid" id="heading">
        <hr />
        <h5>
          Developed By: Integration IT Services Pvt. Ltd.
        </h5>
        <hr />
      </div>

    </div>
  );
}

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Detail from "./components/Detail";
import Bill from "./components/Bill";
import Customer from "./components/Customer";
import Registration from './components/Registration'; // Updated import
import { AuthProvider } from "./AuthContext"; // Ensure correct import path

function App() {
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/detail" element={<Detail />} />
          <Route path="/bill" element={<Bill />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/registration" element={<Registration />} /> {/* Updated route */}
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;

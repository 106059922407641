import React, { useState, useEffect } from "react";
import "./css/dashboard.css";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../AuthContext";

export default function Dashboard() {
  const [user, setUser] = useState("");
  const [shops, setShops] = useState([]);
  const { isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate("/login");
    }

    const getUser = async () => {
      try {
        const response = await fetch(
          "http://nansijewellers.com/php/get_user.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setUser(data.user);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const getShops = async () => {
      try {
        const response = await fetch(
          "http://nansijewellers.com/php/get_shop.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (data.success) {
          setShops(data.shops);
        } else {
          console.error("Failed to fetch shops:", data.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (isAuthenticated) {
      getUser();
      getShops();
    }
  }, [isAuthenticated, loading, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state while checking authentication
  }

  return (
    <div>
      <div className="container-fluid" id="dashboard_heading">
        <hr />
        <h3 className="Heading">WELCOME {user}</h3>
        <hr />
      </div>
      <div className="container-fluid" id="content1">
        <div className="card-container">
          {/* Add new shop registration card */}
          <Link to="/registration" className="card">
            <div className="card-content">
              <hr />
              <h6>
                <i className="fa-solid fa-plus"></i> Register New Shop
              </h6>
              <hr />
            </div>
          </Link>
          {shops.map((shop, index) => (
            <Link to="/detail" state={{ shopName: shop.shop_name }} key={index}>
              <div className="card" key={index}>
                <div className="card-content">
                  <h6>{shop.shop_name}</h6>
                  <hr />
                  <p>{shop.shop_owner_name}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
